.Resizer {
    background: #000;
    opacity: 0.2;
    z-index: 1;
    box-sizing: border-box;
    background-clip: padding-box;
}

.Resizer:hover {
    transition: all 2s ease;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
}

.Resizer.horizontal:hover,
.Resizer.horizontal.resizing {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover,
.Resizer.vertical.resizing {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.DragLayer {
    z-index: 1;
    pointer-events: none;
}

.DragLayer.resizing {
    pointer-events: auto;
}

.DragLayer.horizontal {
    cursor: row-resize;
}

.DragLayer.vertical {
    cursor: col-resize;
}

.SplitPane.vertical {
    max-height: 92.9%;
}

.c-main {
    padding-top: 0px;
}

.selectedLoad {
    background-color: aliceblue;
}

.paginator-order {
    position: absolute;
    bottom: 5%;
}

.table-wrapper nav {
    position: absolute;
    bottom: 5%;
}

td.overflow {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.rtl {
    direction: rtl;
    text-align: left;
}

button.data-quality-report {
    float: right;
    color: white;
    padding: 10px;
}

.sticky {
    position: fixed;
    top: 212;
    width: 220px;
    z-index: 999;
    background-color: white;
    border-radius: 5px;
}

.leaflet-container {
    height: 400px;
    width: 80%;
    margin: 0 auto;
}

.leaflet-pane {
    img {
        position: absolute;
    }
}

.start-left-panel-card {
    height: 80%;
    margin-top: 16px;
}

.save-groupings {
    float: right;
}

.grouping-functions {
    float: right;
}

.grouping-header {
    font-size: 20px;
}

.load-id {
    font-size: 11px;
    color: #696969;
    float: right;
}

.sessionOrderTableWrapper {
    height: 80%;
    overflow: hidden;
    background-color: #FFF;
    font-size: 14px;
}

.stickyTable {
    overflow: scroll;
    height: 100%;
    max-height: 100vh;
    overflow-x: hidden;
    background-color: #fff;

    tbody tr {
        font-size: 12px;

        &.selected-row {
            background: #87cefa;
        }
    }
}

.stickyTable .table{
    table-layout: auto;
}

.stickyTable .table thead {
    position: sticky;
    top: -1;
    background-color: #FFF;
    z-index: 2;
}

.stickyTable .table th {
    width: auto;
}

.c-header-nav-link {
    color: #00001580;
}

.footer {
    background: $bg-light-grey;
    border-top: 0;
}

.btn-success {
    color: $white;
    background-color: map-get($theme-colors, success);
    border-color: map-get($theme-colors, success);
    font-size: 14px;
}

.btn-danger {
    color: $white;
    background-color: map-get($theme-colors, danger);
    border-color: map-get($theme-colors, danger);
    font-size: 14px;
}

.btn-info {
    color: $white;
    background-color: map-get($theme-colors, info);
    border-color: map-get($theme-colors, info);
    font-size: 14px;
}

.btn-info.disabled,
.btn-info:disabled {
    color: $white;
}

.tollbarContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;

    .nav-link.active {
        background-color: #ebedef !important;
    }
}

.text {
    font-size: 14px;
    font-weight: 400;
}

.text-fourteen {
    font-size: 14px;
}

.session-description {
    height: 88.6px;
    color: #768192;
    border: 1px solid #d8dbe0;
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 300;
    line-height: 1.5;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .14), 0 2px 1px -1px rgba(var(--cui-elevation-base-color, 60, 75, 100), .12), 0 1px 3px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), .2);
}

.user-group {
    width: 100%;
    margin-top: 3px;
}

.alternative-orders {
    background: none;
    padding: 40px 10px;
}

.load-button {
    font-size: 20px;
    color: #3c4b64;
}

.transporters-table tr {
    height: 48px;
    vertical-align: middle;
}

.orders-row {
    font-size: 12px;
}

.orders-row.lightblue {
    background-color: lightblue;
}

.orders-row.selected {
    background-color: #D4D3DA;
}

.orders-row.un-selected {
    background-color: #fff;
}

.custom-grey {
    color: #3c4b64;
}

.errorMessage {
    width:100%;
    display:block;
    background-color: #e55353;
    color: #FFF;
    text-align:center;
    margin-top: -10px;
    margin-bottom: 40px;
}