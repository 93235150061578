$dark: #636f83;

body {
    background-color: $white;
}

.c-app {
    background-color: $bg-light-grey;
}

label {
    display: inline-block;
    margin: 0.5rem 0;
}

select,
input {
    margin-top: 3px;
}

.text-primary {
    color: $dark !important;
}

.btn:focus,
.btn.focus {
    outline: 0;
    box-shadow: 0;
}

.text-info {
    color: $text !important;
}

.card-header {
    border: 1px solid;
}

.text-muted {
    color: #768192!important;
}

small, .small {
    font-size: 11px;
    font-weight: 400;
}

.font-weight-bold {
    font-weight: 700!important;
}

.text-value {
    font-size: .875rem;
}

[class^=text-value] {
    font-weight: 600;
}